import React from 'react';
import './tile.scss';

function Tile({ url, titleClass, image, title, subtitle, text }) {
  let img;
  let err = false;

  try {
    img = require(`assets/${image}`);
  } catch (ex) {
    err = true;
  }

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className={`tile ${titleClass}`}>
      <div className="tile-img">{!err ? <img src={img} alt="tile-icon" /> : <div />}</div>
      <div className="tile-body">
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
        <p>{text}</p>
      </div>
    </a>
  );
}

export default Tile;
