import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import getParams from "utils/parameters";
import { validateRequired } from "utils/validate";
import Network from "utils/Network";
import "./passwordModal.scss";

class PasswordModal extends React.Component {
  state = {
    openModal: false,
    oldPass: null,
    newPass: null,
    repeatPass: null,
    intensity: 1,
  };

  openModal = () => {
    this.setState({
      openModal: true,
      oldPass: null,
      newPass: null,
      repeatPass: null,
      intensity: 1,
    });
    document.querySelector("body").classList.add("no-overflow");
  };

  closeModal = () => {
    this.setState({ openModal: false });
    document.querySelector("body").classList.remove("no-overflow");
  };

  handleOldChange = (event) => {
    const { value } = event.target;
    this.setState({ oldPass: value });
  };

  handleNewChange = (event) => {
    const { value } = event.target;
    this.setState({ newPass: value });
  };

  handleRepeatChange = (event) => {
    const { value } = event.target;
    this.setState({ repeatPass: value });
  };

  validate = () => {
    const { oldPass, newPass, repeatPass } = this.state;
    if (!validateRequired(oldPass)) return false;
    if (!validateRequired(newPass)) return false;
    if (!validateRequired(repeatPass)) return false;
    if (newPass !== repeatPass) return false;
    return true;
  };

  hash = (string) => {
    return string.split("").reduce(function(prevHash, currVal) {
      return (prevHash << 5) - prevHash + currVal.charCodeAt(0);
    }, 0);
  };

  doShake = (intensity) => {
    let i = intensity;
    if (intensity !== 5) {
      i++;
    } else {
      i = 1;
    }
    this.setState({ intensity: i });

    document
      .querySelector(".password-modal")
      .classList.add(`error${intensity}`);
    document
      .querySelector(".password-modal")
      .classList.remove(`error${intensity === 1 ? 5 : intensity - 1}`);
  };

  submit = () => {
    const isValid = this.validate();
    const { oldPass, newPass, intensity } = this.state;
    if (isValid) {
      Network.post(
        `${this.props.baseUrl}rest/Users/SetPass/${this.props.userData.username}`,
        {
          oldPassword: this.hash(oldPass),
          newPassword: this.hash(newPass),
        }
      ).then((res) => {
        if (res.data.error) {
          this.doShake(intensity);
        } else {
          this.closeModal();
        }
      });
    } else {
      this.doShake(intensity);
    }
  };

  render() {
    const { openModal } = this.state;
    const { params } = this.props;

    if (!openModal) return null;

    return (
      <div className="modal-wrapper">
        <div className="dimmer" onClick={this.closeModal} />
        <div className="modal password-modal">
          <div className="modal-header">
            <span />
            <h1>{getParams(params, "APP_LOGIN_CHANGE_PWD")}</h1>
            <FontAwesomeIcon icon={faTimes} onClick={this.closeModal} />
          </div>
          <div className="form-field">
            <label className="small-label">
              {getParams(params, "APP_LABEL_OLD_PWD")}
            </label>
            <input type="password" onChange={this.handleOldChange} />
          </div>
          <div className="form-field">
            <label className="small-label">
              {getParams(params, "APP_LABEL_NEW_PWD")}
            </label>
            <input type="password" onChange={this.handleNewChange} />
          </div>
          <div className="form-field">
            <label className="small-label">
              {getParams(params, "APP_LABEL_REPEAT_NEW_PWD")}
            </label>
            <input type="password" onChange={this.handleRepeatChange} />
          </div>
          <button type="button" onClick={this.submit}>
            {getParams(params, "APP_LABEL_CONFIRM")}
          </button>
        </div>
      </div>
    );
  }
}

export default PasswordModal;
