import React from "react";
import Logo from "assets/img/Dehora_Login.png";
import { validateRequired } from "utils/validate";
import getParams from "utils/parameters";
import Network from "utils/Network";
import "./login.scss";

class Login extends React.Component {
  state = {
    username: "",
    password: "",
    intensity: 1,
    isLoading: false,
  };

  handleUsernameChange = (event) => {
    const { value } = event.target;
    this.setState({ username: value });
  };

  handlePasswordChange = (event) => {
    const { value } = event.target;
    this.setState({ password: value });
  };

  validate = () => {
    const { username, password } = this.state;
    if (!validateRequired(username)) return false;
    if (!validateRequired(password)) return false;
    return true;
  };

  doShake = (intensity) => {
    let i = intensity;
    if (intensity !== 5) {
      i++;
    } else {
      i = 1;
    }
    this.setState({ intensity: i });

    document.querySelector(".login-modal").classList.add(`error${intensity}`);
    if (intensity === 1) {
      document.querySelector(".login-modal").classList.remove(`error${5}`);
    } else {
      document
        .querySelector(".login-modal")
        .classList.remove(`error${intensity - 1}`);
    }
  };

  hash = (string) => {
    return string.split("").reduce(function(prevHash, currVal) {
      return (prevHash << 5) - prevHash + currVal.charCodeAt(0);
    }, 0);
  };

  submit = (e) => {
    e.preventDefault();
    const isValid = this.validate();
    const { username, password, intensity } = this.state;
    if (isValid) {
      this.setState({ isLoading: true });
      Network.post(
        `${this.props.baseUrl}login`,
        `username=${username}&pwd=${this.hash(password)}`
      ).then((res) => {
        this.setState({ isLoading: false });
        if (res.data.id) {
          this.props.doLogin(res.data);
        } else if (res.data.error === "RESET_PWD") {
          this.props.doResetPass({ username, password });
        } else {
          this.doShake(intensity);
        }
      });
    } else {
      this.doShake(intensity);
    }
  };

  render() {
    const { isLoading } = this.state;
    const { params } = this.props;

    return (
      <div className="login">
        <div className="login-body">
          <div className="modal login-modal">
            <div className="modal-image">
              <img
                src={Logo}
                alt="dehora-logo"
                title={getParams(params, "APPLICATION_DB")}
              />
            </div>
            <div className="modal-body">
              <div>
                {this.props.loginMessage && <p>{this.props.loginMessage}</p>}
              </div>
              <form onSubmit={this.submit}>
                <div className="form-field">
                  <label htmlFor="name">
                    {getParams(params, "APP_LOGIN_USER")}
                  </label>
                  <input
                    id="name"
                    type="text"
                    onChange={this.handleUsernameChange}
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="pass">
                    {getParams(params, "APP_LOGIN_PWD")}
                  </label>
                  <input
                    id="pass"
                    type="password"
                    onChange={this.handlePasswordChange}
                  />
                </div>
                <button type="submit" className={isLoading ? "loading" : ""}>
                  {getParams(params, "APP_LOGIN_BTN")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
