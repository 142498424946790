export function convertDateToYMD(date) {
  const d = new Date(date);
  if (d.getDate().toString().length === 1) {
    if ((d.getMonth() + 1).toString().length === 1) {
      return `${d.getFullYear()}-0${d.getMonth() + 1}-0${d.getDate()}`;
    }
    return `${d.getFullYear()}-${d.getMonth() + 1}-0${d.getDate()}`;
  }
  if ((d.getMonth() + 1).toString().length === 1) {
    return `${d.getFullYear()}-0${d.getMonth() + 1}-${d.getDate()}`;
  }
  return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
}

export function convertDateToDMY(date) {
  const d = new Date(date);
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
}

export function convertYMDToDMY(date) {
  return `${date.split('-')[2]}/${date.split('-')[1]}/${date.split('-')[0]}`;
}

export function convertDMYToYMD(date) {
  return `${date.split('/')[2]}-${date.split('/')[1]}-${date.split('/')[0]}`;
}

export function convertDecimalToMinutes(decimal) {
  if (!decimal || decimal === 0 || !decimal.toString().split('.')[1]) return 0;
  let minutes = decimal
    .toString()
    .split('.')[1]
    .substring(0, 2);
  if (minutes.toString().length === 1) {
    minutes *= 10;
  }
  minutes = Math.round((minutes / 100) * 60);
  return minutes;
}

export function convertDecimalToTime(decimal) {
  if (!decimal || parseInt(decimal, 10) === 0) return '00:00';
  let hours;
  let minutes;
  if (decimal.toString().split('.')[1]) {
    hours = decimal.toString().split('.')[0];
    minutes = convertDecimalToMinutes(`0.${decimal.toString().split('.')[1]}`);
  } else {
    hours = decimal;
    minutes = '00';
  }
  if (minutes.toString().length === 1) {
    minutes = `0${minutes}`;
  }
  return `${hours}:${minutes}`;
}

export function convertSecondsToHours(seconds) {
  return Math.round((seconds / 3600) * 100) / 100;
}
