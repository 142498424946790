import React from "react";
import Network from "utils/Network";
import Banner from "banner/Banner";
import Main from "main/Main";
import Login from "login/Login";
import ResetPass from "resetPassword/ResetPassword";
import PasswordModal from "passwordModal/PasswordModal";
import Spinner from "assets/img/Dehora_Circle.png";
import Logo from "assets/img/Dehora-200px.png";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.passwordModalRef = React.createRef();
    this.state = {
      loading: true,
      isLoggedIn: false,
      loginMessage: "",
      resetPass: false,
      resetData: null,
      parameters: null,
      baseUrl: "",
      error: false,
      noUser: false,
      userData: null,
    };
  }

  componentDidMount() {
    const parsedData = window.location.pathname.split("/");
    const d = parsedData[parsedData.length - 1];

    if (d === "" || d === "index.html") parsedData.splice(-1, 1);

    const e = window.location.href.split("/");
    if (e[2] === "localhost:3000") {
      e[0] = "https:";
      e[2] = "dehora-lt-0063";
    }

    const baseUrl = `${[e[0], "//", e[2]].join("")}${parsedData.join("/")}/`;
    this.setState({ baseUrl });

    this.getParameters(baseUrl, null);

    if (window.app && window.app.user) {
      if (window.app.user.id) {
        this.setState({ isLoggedIn: true, userData: window.app.user });
      } else if (
        window.app.user.error &&
        window.app.user.error.includes("Gebruiker niet gekend")
      )
        this.setState({ noUser: true });
    }
  }

  getParameters = (baseUrl, lang) => {
    Network.get(`${baseUrl}rest/Parameters/` + (lang ? lang + "/" : ""))
      .then((res) => {
        this.setState({ parameters: res.parameters, loading: false });
      })
      .catch(() => this.setState({ error: true, loading: false }));
  };

  doResetPass = (res) => {
    this.setState({
      resetPass: true,
      resetData: res,
    });
  };

  undoResetPass = () => {
    this.setState({
      resetPass: false,
      resetData: null,
      loginMessage: "Wachtwoord succesvol gereset. U kan nu inloggen.",
    });
  };

  doLogin = (res) => {
    this.setState({
      isLoggedIn: true,
      userData: res,
    });
  };

  doLogout = () => {
    this.setState(
      {
        isLoggedIn: false,
        userData: null,
      },
      () =>
        window.loginLocation ? (window.location = window.loginLocation) : null
    );
  };

  openPasswordModal = () => {
    this.passwordModalRef.current.openModal();
  };

  render() {
    const {
      loading,
      error,
      parameters,
      isLoggedIn,
      loginMessage,
      resetPass,
      resetData,
      baseUrl,
      userData,
      noUser,
    } = this.state;

    if (loading)
      return (
        <div className="fullscreen">
          <img src={Spinner} alt="loading" className="spinner" />
        </div>
      );

    if (error)
      return (
        <div className="fullscreen">
          <img src={Logo} alt="dehora logo" />
          <span>Er is iets mis gegaan</span>
        </div>
      );

    if (noUser)
      return (
        <div className="fullscreen">
          <img src={Logo} alt="dehora logo" />
          <span>Deze gebruiker bestaat niet</span>
        </div>
      );

    return (
      <div>
        <Banner
          isLoggedIn={isLoggedIn}
          parameters={parameters}
          userData={userData}
          openPasswordModal={this.openPasswordModal}
          baseUrl={this.state.baseUrl}
          getParameters={this.getParameters}
        />
        {!isLoggedIn ? (
          resetPass ? (
            <ResetPass
              doLogin={this.doLogin}
              undoResetPass={this.undoResetPass}
              resetData={resetData}
              params={parameters}
              baseUrl={baseUrl}
            />
          ) : (
            <Login
              doLogin={this.doLogin}
              doResetPass={this.doResetPass}
              loginMessage={loginMessage}
              params={parameters}
              baseUrl={baseUrl}
            />
          )
        ) : (
          <Main
            params={parameters}
            baseUrl={baseUrl}
            userData={userData}
            doLogout={this.doLogout}
          />
        )}
        <PasswordModal
          ref={this.passwordModalRef}
          baseUrl={baseUrl}
          params={parameters}
          userData={userData}
        />
      </div>
    );
  }
}

export default App;
