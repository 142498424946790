import React from "react";
import "./widget.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getParams from "utils/parameters";
import {
  faSortUp,
  faSortDown,
  faSort,
  faFilter,
  faTrash,
  faSpinner,
  faExpandAlt,
  faCompressAlt,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Network from "utils/Network";
import { convertDateToDMY, convertDateToYMD } from "utils/conversionFunctions";

class Widget extends React.Component {
  state = {
    loading: false,
    originalData: null,
    data: [],
    periodStart: "",
    periodEnd: "",
    sortField: null,
    sortType: 0,
    filterFields: {},
    filterValues: {},
    selectedItems: [],
    expandWidget: false,
    bulkSelectChecked: false,
  };

  componentDidMount() {
    const today = new Date();
    this.setState(
      {
        periodStart: convertDateToYMD(today),
        periodEnd: convertDateToYMD(today),
      },
      () => this.getData(true)
    );
  }

  getData = (isInitial) => {
    const { periodStart, periodEnd } = this.state;
    this.setState({ loading: true });

    Network.post(`${this.props.baseUrl}${this.props.url}`, {
      filters: [
        { key: ".pb", name: "START_DATE", value: periodStart },
        { key: ".pb", name: "END_DATE", value: periodEnd },
      ],
    })
      .then((res) => {
        this.setState({ loading: false });
        if (Array.isArray(res.data)) {
          const data = [];
          res.data.forEach((e) => {
            const keys = JSON.parse(e.keys);
            let tempQua = keys.UniqueShiftId
              ? keys.UniqueShiftId
              : keys.actName;
            let qua;
            let key;

            if (tempQua) {
              tempQua = tempQua.split("-");

              if (tempQua[0].length === 1) {
                if (
                  tempQua[tempQua.length - 3] &&
                  tempQua[tempQua.length - 2].length === 4 &&
                  tempQua[tempQua.length - 1].length === 4
                ) {
                  key = tempQua[tempQua.length - 3];
                  tempQua.splice(-3, 3);
                  tempQua.splice(0, 1);
                  qua = tempQua.join("-");
                } else {
                  tempQua.splice(0, 1);
                  qua = tempQua.join("-");
                }
              } else {
                qua = tempQua;
              }
            }

            const msg = getParams(this.props.params, e.message)
              .replace("@DATE@", convertDateToDMY(keys.theDate))
              .replace("@OLDREQ@", keys.prevRequired)
              .replace("@NEWREQ@", keys.required)
              .replace("@OLDTIMEFROM@", keys.prevTimeFrom)
              .replace("@NEWTIMEFROM@", keys.timeFrom)
              .replace("@OLDTIMETO@", keys.prevTimeTo)
              .replace("@NEWTIMETO@", keys.timeTo)
              .replace("@QUA@", keys.Qualification)
              .replace("@COUNT@", keys.counter)
              .replace("@KEY@", keys.skillName)
              .replace("@ERR@", keys.error);

            data.push({
              id: e.id,
              type: e.type,
              collection: qua,
              date: convertDateToDMY(e.date),
              message: msg,
              isRead:
                e.isRead === 0
                  ? getParams(this.props.params, "APP_LABEL_UNREAD")
                  : getParams(this.props.params, "APP_LABEL_ISREAD"),
            });
          });

          this.setState(
            {
              originalData: data,
              data,
            },
            this.updateFilters
          );

          if (isInitial) {
            this.openFilter("isRead");
            this.handleChange(
              [
                {
                  value: getParams(this.props.params, "APP_LABEL_UNREAD"),
                  label: getParams(this.props.params, "APP_LABEL_UNREAD"),
                },
              ],
              "isRead"
            );
          }
        }
      })
      .catch(() => this.props.doLogout());
  };

  sortData = (e) => {
    const { sortField, sortType, originalData } = this.state;
    const { data } = this.state;
    let d;

    if (sortField !== e || (sortField === e && sortType === 0)) {
      d = data
        .concat()
        .sort((a, b) => (a[e] > b[e] ? 1 : b[e] > a[e] ? -1 : 0));
      this.setState({ sortField: e, sortType: 1 });
    }

    if (sortField === e && sortType === 1) {
      d = data
        .concat()
        .sort((a, b) => (a[e] < b[e] ? 1 : b[e] < a[e] ? -1 : 0));
      this.setState({ sortType: 2 });
    }

    if (sortField === e && sortType === 2) {
      d = originalData;
      this.setState({ sortType: 0 });
    }

    this.setState({ data: d });
  };

  openFilter = (type) => {
    const { filterFields, filterValues } = this.state;

    // if the filtertype doesn't exist yet, make it
    if (!filterFields[type]) {
      filterFields[type] = [];
      filterValues[type] = [];
      this.setState({ filterFields, filterValues }, this.updateFilters);
    }
  };

  closeFilter = (type) => {
    const { filterFields, filterValues } = this.state;

    const fields = Object.keys(filterFields).reduce(
      (a, b) => (b === type ? { ...a } : { ...a, [b]: filterFields[b] }),
      {}
    );

    const values = Object.keys(filterValues).reduce(
      (a, b) => (b === type ? { ...a } : { ...a, [b]: filterValues[b] }),
      {}
    );

    this.setState(
      { filterFields: fields, filterValues: values },
      this.updateFilters
    );
  };

  handleChange = (selectedOption, type) => {
    const { filterValues } = this.state;
    filterValues[type] = selectedOption;
    this.setState({ filterValues }, this.updateFilters);
  };

  checkAllFilters = (row) => {
    const { filterValues } = this.state;
    let result = false;
    // check if there are any filters, else display everything
    if (Object.keys(filterValues).length > 0) {
      let a = 0;
      // loop through the filter types
      Object.keys(filterValues).forEach((type) => {
        // if the filter type is defined and has a length greater than zero
        if (filterValues[type] && filterValues[type].length > 0) {
          filterValues[type].forEach((e) => {
            // if the value of the current type of the row is included in the filter of that type
            if (row[type] === e.value) {
              a++;
            }
          });
        } else {
          a++;
        }
      });

      // if the row matches all filter types: show it
      if (a === Object.keys(filterValues).length) {
        result = true;
      }
    } else {
      result = true;
    }
    return result;
  };

  checkPrevFilters = (row, rowType) => {
    const { filterFields, filterValues } = this.state;
    let result = false;
    // check if there are any filters, else display everything
    if (Object.keys(filterValues).length > 0) {
      let a = 0;
      // loop through the filter types
      Object.keys(filterValues).forEach((type) => {
        if (
          Object.keys(filterFields).indexOf(type) <
          Object.keys(filterFields).indexOf(rowType)
        ) {
          // if the filter type is defined and has a length greater than zero
          if (filterValues[type] && filterValues[type].length > 0) {
            filterValues[type].forEach((e) => {
              // if the value of the current type of the row is included in the filter of that type
              if (row[type] === e.value) {
                a++;
              }
            });
          } else {
            a++;
          }
        } else {
          a++;
        }
      });

      // if the row matches all filter types: show it
      if (a === Object.keys(filterValues).length) {
        result = true;
      }
    } else {
      result = true;
    }
    return result;
  };

  updateFilters = () => {
    const { data, filterFields } = this.state;

    Object.keys(filterFields).forEach((type) => {
      const result = [];
      data.forEach((e) => {
        // if the value is not yet in the result
        if (result.findIndex((i) => i.label === e[type]) === -1) {
          // only add values that pass the previous filters
          if (this.checkPrevFilters(e, type)) {
            result.push({ value: e[type], label: e[type] });
          }
        }
      });
      result.sort((a, b) =>
        a.label
          ? a.label.toLowerCase()
          : a.label > b.label
          ? b.label.toLowerCase()
          : b.label
          ? 1
          : -1
      );
      filterFields[type] = result;
    });

    this.setState({ filterFields });
    this.checkBulkSelect();
  };

  handlePeriodStartChange = (event, blur) => {
    const { value } = event.target;
    if (blur && value > this.state.periodEnd)
      this.setState({ periodEnd: value });
    this.setState({ periodStart: value });
  };

  handlePeriodEndChange = (event, blur) => {
    const { value } = event.target;
    if (blur && value < this.state.periodStart)
      this.setState({ periodStart: value });
    this.setState({ periodEnd: value });
  };

  handleCheckBoxChange = (id) => {
    const { selectedItems } = this.state;

    if (selectedItems.indexOf(id) === -1) {
      selectedItems.push(id);
    } else {
      selectedItems.splice(selectedItems.indexOf(id), 1);
    }

    this.setState({ selectedItems });
    this.checkBulkSelect();
  };

  bulkUpdate = (isRead) => {
    let bulk = "";

    this.state.selectedItems.forEach((e) => {
      bulk += `${e},`;
    });

    this.setState({ loading: true });

    Network.post(`${this.props.baseUrl}${this.props.url}Update`, {
      filters: [
        { key: ".pb", name: "BULKDATA", value: bulk },
        { key: ".pb", name: "DELETED", value: isRead },
      ],
    }).then(() => {
      this.getData();
      this.setState({ selectedItems: [] });
    });
  };

  bulkSelect = (event) => {
    this.setState({ bulkSelectChecked: event });
    this.state.data.forEach((e) => {
      if (
        this.checkAllFilters(e) &&
        (event
          ? this.state.selectedItems.indexOf(e.id) === -1
          : this.state.selectedItems.indexOf(e.id) !== -1)
      ) {
        this.handleCheckBoxChange(e.id);
      }
    });
  };

  checkBulkSelect = () => {
    const { data, selectedItems } = this.state;
    let filteredData = [];
    data.forEach((e) => {
      if (this.checkAllFilters(e)) filteredData.push(e);
    });
    if (filteredData.length == selectedItems.length) {
      this.setState({ bulkSelectChecked: true });
    } else {
      this.setState({ bulkSelectChecked: false });
    }
  };

  toggleExpandWidget = () => {
    this.setState((prevState) => ({ expandWidget: !prevState.expandWidget }));
  };

  render() {
    const { title, subtitle, tableHeader, params, expTableHeader } = this.props;
    const {
      sortField,
      sortType,
      data,
      periodStart,
      periodEnd,
      filterFields,
      selectedItems,
      expandWidget,
      bulkSelectChecked,
    } = this.state;

    return (
      <div className="widget">
        <h1>{getParams(params, title)}</h1>
        <div className={`widget-body${expandWidget ? " expand" : ""}`}>
          {this.state.loading && (
            <div className="loading-overlay">
              <FontAwesomeIcon icon={faSpinner} />
            </div>
          )}
          <div>
            <div className="widget-title">
              <h2>{getParams(params, subtitle)}</h2>
              <div>
                <div className="widget-icon">
                  <FontAwesomeIcon icon={faSyncAlt} onClick={this.getData} />
                  <span>Vernieuwen</span>
                </div>
                <div className="widget-icon">
                  <FontAwesomeIcon
                    icon={expandWidget ? faCompressAlt : faExpandAlt}
                    onClick={this.toggleExpandWidget}
                  />
                  <span>{expandWidget ? "Verkleinen" : "Vergroten"}</span>
                </div>
              </div>
            </div>
            <div className="widget-period">
              <label>{getParams(params, "APP_LABEL_PERIOD")}</label>
              <input
                type="date"
                value={periodStart}
                onChange={(e) => this.handlePeriodStartChange(e, false)}
                onBlur={(e) => this.handlePeriodStartChange(e, true)}
                required
              />
              <input
                type="date"
                value={periodEnd}
                onChange={(e) => this.handlePeriodEndChange(e, false)}
                onBlur={(e) => this.handlePeriodEndChange(e, true)}
                required
              />
            </div>
            <div className="widget-filters">
              {Object.keys(filterFields).length > 0 && (
                <table>
                  <tbody>
                    {Object.keys(filterFields).map((keyName, i) => (
                      <tr key={i}>
                        <td>
                          <span>
                            {getParams(
                              params,
                              `APP_LABEL_${keyName.toUpperCase()}`
                            )}
                            :
                          </span>
                        </td>
                        <td>
                          <Select
                            options={filterFields[keyName]}
                            isMulti
                            className="multi-select"
                            onChange={(event) =>
                              this.handleChange(event, keyName)
                            }
                            value={this.state.filterValues[keyName]}
                          />
                        </td>
                        <td>
                          <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() => this.closeFilter(keyName)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            <div className="widget-table">
              <div className="widget-table-body">
                <table>
                  <thead>
                    <tr>
                      {(expandWidget ? expTableHeader : tableHeader).map(
                        (e, i) => (
                          <th key={i}>
                            <div
                              className={
                                "flexbox" + (e == "action" ? " center" : "")
                              }
                            >
                              {e !== "action" ? (
                                <>
                                  <div onClick={() => this.sortData(e)}>
                                    {sortField !== e && (
                                      <FontAwesomeIcon icon={faSort} />
                                    )}
                                    {sortField === e && sortType === 0 && (
                                      <FontAwesomeIcon icon={faSort} />
                                    )}
                                    {sortField === e && sortType === 1 && (
                                      <FontAwesomeIcon icon={faSortUp} />
                                    )}
                                    {sortField === e && sortType === 2 && (
                                      <FontAwesomeIcon icon={faSortDown} />
                                    )}{" "}
                                    <span>
                                      {getParams(
                                        params,
                                        `APP_LABEL_${e.toUpperCase()}`
                                      )}
                                    </span>
                                  </div>
                                  <FontAwesomeIcon
                                    icon={faFilter}
                                    onClick={() => this.openFilter(e)}
                                  />
                                </>
                              ) : (
                                <input
                                  type="checkbox"
                                  onClick={(f) =>
                                    this.bulkSelect(!bulkSelectChecked)
                                  }
                                  checked={bulkSelectChecked}
                                />
                              )}
                            </div>
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.map((e, i) => {
                        if (this.checkAllFilters(e)) {
                          return (
                            <tr key={i}>
                              <td>{e.type}</td>
                              <td>{e.collection}</td>
                              <td>{e.date}</td>
                              <td>{e.message}</td>
                              <td>{e.isRead}</td>
                              <td className="center">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    this.handleCheckBoxChange(e.id)
                                  }
                                  checked={selectedItems.indexOf(e.id) > -1}
                                />
                              </td>
                            </tr>
                          );
                        }
                        return null;
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="widget-buttons">
            <button
              type="button"
              onClick={() => this.bulkUpdate(1)}
              disabled={selectedItems.length === 0}
            >
              {getParams(params, "APP_BUTTON_ISREAD")}
            </button>
            <button
              type="button"
              onClick={() => this.bulkUpdate(0)}
              disabled={selectedItems.length === 0}
            >
              {getParams(params, "APP_BUTTON_UNREAD")}
            </button>
          </div>
        </div>
        {expandWidget && (
          <div className="widget-dimmer" onClick={this.toggleExpandWidget} />
        )}
      </div>
    );
  }
}

export default Widget;
