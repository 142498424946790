import React from "react";
import "./widget.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getParams from "utils/parameters";
import {
  faSortUp,
  faSortDown,
  faSort,
  faFilter,
  faTrash,
  faSpinner,
  faExpandAlt,
  faCompressAlt,
  faSyncAlt,
  faPaperclip,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Network from "utils/Network";
import { convertDateToYMD, convertYMDToDMY } from "utils/conversionFunctions";

class Widget extends React.Component {
  state = {
    originalData: null,
    data: null,
    periodStart: "",
    periodEnd: "",
    sortField: null,
    sortType: 0,
    filterFields: {},
    filterValues: {},
    selectedItems: [],
    loading: false,
    showRemarkArea: false,
    bulkUpdateType: null,
    requestRemark: "",
    expandWidget: false,
    bulkSelectChecked: false,
    showApproveButton: true,
    showRejectButton: true,
  };

  componentDidMount() {
    const { userFilters } = this.props;
    let filterValues = {};

    if (userFilters) {
      userFilters
        .filter(
          (e) =>
            e.key === "#ptr" && e.name !== "START_DATE" && e.name !== "END_DATE"
        )
        .forEach((e) => {
          const key = e.name.toLowerCase();
          if (!filterValues[key]) filterValues[key] = [];
          filterValues[key] = filterValues[key].concat({
            value: e.value.split(",")[0],
            label: e.value.split(",")[1],
          });
        });
    }

    const today = new Date();
    this.setState(
      {
        periodStart: userFilters
          ? (
              userFilters.find(
                (e) => e.key === "#ptr" && e.name === "START_DATE"
              ) || { value: convertDateToYMD(today) }
            ).value
          : convertDateToYMD(today),
        periodEnd: userFilters
          ? (
              userFilters.find(
                (e) => e.key === "#ptr" && e.name === "END_DATE"
              ) || { value: convertDateToYMD(today) }
            ).value
          : convertDateToYMD(today),
        filterValues,
      },
      () => this.getData(true)
    );
  }

  getData = (isInitial) => {
    const { periodStart, periodEnd, filterValues } = this.state;
    const { rscId } = this.props;
    this.setState({ loading: true });

    let filters = [
      { key: "#ptr", name: "START_DATE", value: periodStart },
      { key: "#ptr", name: "END_DATE", value: periodEnd },
    ];

    Object.keys(filterValues).forEach((e) => {
      if (filterValues[e])
        filterValues[e].forEach((f) => {
          filters = filters.concat({
            key: "#ptr",
            name: e.toUpperCase(),
            value: f.value + "," + f.label,
          });
        });
    });

    Network.post(`${this.props.baseUrl}${this.props.url}`, {
      filters,
      userId: rscId,
      rscId: rscId,
    })
      .then((res) => {
        this.setState({ loading: false });
        if (Array.isArray(res.data)) {
          const data = [];

          res.data.forEach((e) => {
            const temp = e;

            if (e.status === 1) temp.status = "APP_LABEL_ACCEPTED";
            if (e.status === 2) temp.status = "APP_LABEL_REQUESTED";
            if (e.status === 3) temp.status = "APP_LABEL_REJECTED";

            data.push(temp);
          });

          this.setState(
            {
              originalData: res.data,
              data,
            },
            () => {
              if (isInitial) {
                let filterFields = {};
                Object.keys(filterValues).forEach(
                  (e) => (filterFields[e] = [])
                );
                this.setState({ filterFields }, this.updateFilters);
              }
            }
          );
        }
      })
      .catch(() => this.props.doLogout());
  };

  sortData = (e) => {
    const { sortField, sortType, originalData } = this.state;
    const { data } = this.state;
    let d;

    if (sortField !== e || (sortField === e && sortType === 0)) {
      d = data
        .concat()
        .sort((a, b) => (a[e] > b[e] ? 1 : b[e] > a[e] ? -1 : 0));
      this.setState({ sortField: e, sortType: 1 });
    }

    if (sortField === e && sortType === 1) {
      d = data
        .concat()
        .sort((a, b) => (a[e] < b[e] ? 1 : b[e] < a[e] ? -1 : 0));
      this.setState({ sortType: 2 });
    }

    if (sortField === e && sortType === 2) {
      d = originalData;
      this.setState({ sortType: 0 });
    }

    this.setState({ data: d });
  };

  openFilter = (type) => {
    const { filterFields, filterValues } = this.state;

    // if the filtertype doesn't exist yet, make it
    if (!filterFields[type]) {
      filterFields[type] = [];
      filterValues[type] = [];
      this.setState({ filterFields, filterValues }, this.updateFilters);
    }
  };

  closeFilter = (type) => {
    const { filterFields, filterValues } = this.state;

    const fields = Object.keys(filterFields).reduce(
      (a, b) => (b === type ? { ...a } : { ...a, [b]: filterFields[b] }),
      {}
    );

    const values = Object.keys(filterValues).reduce(
      (a, b) => (b === type ? { ...a } : { ...a, [b]: filterValues[b] }),
      {}
    );

    this.setState(
      { filterFields: fields, filterValues: values },
      this.updateFilters
    );
  };

  handleChange = (selectedOption, type) => {
    const { filterValues } = this.state;
    filterValues[type] = selectedOption;
    this.setState({ filterValues }, this.updateFilters);
  };

  checkAllFilters = (row) => {
    const { filterValues } = this.state;
    let result = false;
    // check if there are any filters, else display everything
    if (Object.keys(filterValues).length > 0) {
      let a = 0;
      // loop through the filter types
      Object.keys(filterValues).forEach((type) => {
        // if the filter type is defined and has a length greater than zero
        if (filterValues[type] && filterValues[type].length > 0) {
          filterValues[type].forEach((e) => {
            // if the value of the current type of the row is included in the filter of that type
            if (row[type] === e.value) {
              a++;
            }
          });
        } else {
          a++;
        }
      });

      // if the row matches all filter types: show it
      if (a === Object.keys(filterValues).length) {
        result = true;
      }
    } else {
      result = true;
    }
    return result;
  };

  checkPrevFilters = (row, rowType) => {
    const { filterFields, filterValues } = this.state;
    let result = false;
    // check if there are any filters, else display everything
    if (Object.keys(filterValues).length > 0) {
      let a = 0;
      // loop through the filter types
      Object.keys(filterValues).forEach((type) => {
        if (
          Object.keys(filterFields).indexOf(type) <
          Object.keys(filterFields).indexOf(rowType)
        ) {
          // if the filter type is defined and has a length greater than zero
          if (filterValues[type] && filterValues[type].length > 0) {
            filterValues[type].forEach((e) => {
              // if the value of the current type of the row is included in the filter of that type
              if (row[type] === e.value) {
                a++;
              }
            });
          } else {
            a++;
          }
        } else {
          a++;
        }
      });

      // if the row matches all filter types: show it
      if (a === Object.keys(filterValues).length) {
        result = true;
      }
    } else {
      result = true;
    }
    return result;
  };

  updateFilters = () => {
    const { data, filterFields } = this.state;

    Object.keys(filterFields).forEach((type) => {
      const result = [];
      data.forEach((e) => {
        // if the value is not yet in the result
        if (
          result.findIndex(
            (i) => i.label === getParams(this.props.params, e[type])
          ) === -1 &&
          result.findIndex((i) => i.label === convertYMDToDMY(e[type])) === -1
        ) {
          // only add values that pass the previous filters
          if (this.checkPrevFilters(e, type)) {
            if (type === "date") {
              result.push({
                value: e[type],
                label: convertYMDToDMY(e[type]),
              });
            } else {
              result.push({
                value: e[type],
                label: getParams(this.props.params, e[type]),
              });
            }
          }
        }
      });
      result.sort((a, b) =>
        a.label
          ? a.label.toLowerCase()
          : a.label > b.label
          ? b.label.toLowerCase()
          : b.label
          ? 1
          : -1
      );
      filterFields[type] = result;
    });

    this.setState({ filterFields });
    this.checkBulkSelect();
  };

  handlePeriodStartChange = (event, blur) => {
    const { value } = event.target;
    if (blur && value > this.state.periodEnd)
      this.setState({ periodEnd: value });
    this.setState({ periodStart: value });
  };

  handlePeriodEndChange = (event, blur) => {
    const { value } = event.target;
    if (blur && value < this.state.periodStart)
      this.setState({ periodStart: value });
    this.setState({ periodEnd: value });
  };

  handleCheckBoxChange = (shiftId) => {
    const { selectedItems } = this.state;

    if (selectedItems.indexOf(shiftId) === -1) {
      selectedItems.push(shiftId);
    } else {
      selectedItems.splice(selectedItems.indexOf(shiftId), 1);
    }

    this.setState({ selectedItems }, () => {
      this.checkBulkSelect();
      this.checkButtons();
    });
  };

  bulkUpdate = (bulkUpdateType) => {
    this.setState({ showRemarkArea: true, bulkUpdateType });
  };

  bulkSelect = (event) => {
    this.setState({ bulkSelectChecked: event });
    this.state.data.forEach((e) => {
      const id = `${e.areaId},${e.rscId},${e.date},${e.newActId},${e.shiftId},${e.status}`;
      if (
        this.checkAllFilters(e) &&
        (event
          ? this.state.selectedItems.indexOf(id) === -1
          : this.state.selectedItems.indexOf(id) !== -1)
      ) {
        this.handleCheckBoxChange(id);
      }
    });
  };

  checkBulkSelect = () => {
    const { data, selectedItems } = this.state;
    let filteredData = [];
    data.forEach((e) => {
      if (this.checkAllFilters(e)) filteredData.push(e);
    });
    if (filteredData.length == selectedItems.length) {
      this.setState({ bulkSelectChecked: true });
    } else {
      this.setState({ bulkSelectChecked: false });
    }
  };

  sendUpdate = () => {
    const filters = [];

    this.state.selectedItems.forEach((e) => {
      let arr = e.split(",");
      arr.pop();
      let ee = arr.join(",");
      ee += `,${this.state.requestRemark.replace(
        /[^a-z0-9.?!;:/_()=<>&%@+$ -]/gi,
        ""
      )}`;
      filters.push({ key: ".pb", name: "BULKDATA", value: ee });
    });

    this.setState({ loading: true });

    Network.post(
      `${this.props.baseUrl}${this.props.url}${
        this.state.bulkUpdateType === 0 ? "Approve" : "Reject"
      }`,
      {
        filters,
      }
    ).then(() => {
      this.getData();
      this.setState({
        selectedItems: [],
        showRemarkArea: false,
        bulkUpdateType: null,
        requestRemark: "",
      });
    });
  };

  cancelUpdate = () => {
    this.setState({
      showRemarkArea: false,
      bulkUpdateType: null,
      requestRemark: "",
    });
  };

  handleTextareaChange = (e) => {
    this.setState({ requestRemark: e.target.value });
  };

  toggleExpandWidget = () => {
    this.setState((prevState) => ({ expandWidget: !prevState.expandWidget }));
  };

  showComment = (id) => {
    document
      .querySelector(`.reqrow_${id} .req-comment`)
      .classList.remove("hide");
    document.querySelector(`.reqrow_${id} .req-comment`).classList.add("show");
  };

  hideComment = (id) => {
    document
      .querySelector(`.reqrow_${id} .req-comment`)
      .classList.remove("show");
    document.querySelector(`.reqrow_${id} .req-comment`).classList.add("hide");
  };

  checkButtons = () => {
    let approveBtn = true,
      rejectBtn = true;
    this.state.selectedItems.forEach((e) => {
      if (e.split(",")[5] == "APP_LABEL_ACCEPTED") approveBtn = false;
      if (e.split(",")[5] == "APP_LABEL_REJECTED") rejectBtn = false;
    });
    this.setState({
      showApproveButton: approveBtn,
      showRejectButton: rejectBtn,
    });
  };

  render() {
    const { title, subtitle, tableHeader, expTableHeader, params } = this.props;
    const {
      sortField,
      sortType,
      data,
      periodStart,
      periodEnd,
      filterFields,
      loading,
      showRemarkArea,
      expandWidget,
      bulkSelectChecked,
      showApproveButton,
      showRejectButton,
    } = this.state;

    return (
      <div className="widget">
        <h1>{getParams(params, title)}</h1>
        <div className={`widget-body${expandWidget ? " expand" : ""}`}>
          {loading && (
            <div className="loading-overlay">
              <FontAwesomeIcon icon={faSpinner} />
            </div>
          )}
          <div>
            <div className="widget-title">
              <h2>{getParams(params, subtitle)}</h2>
              <div>
                <div className="widget-icon">
                  <FontAwesomeIcon
                    icon={faSyncAlt}
                    onClick={() => this.getData()}
                  />
                  <span>Vernieuwen</span>
                </div>
                <div className="widget-icon">
                  <FontAwesomeIcon
                    icon={expandWidget ? faCompressAlt : faExpandAlt}
                    onClick={this.toggleExpandWidget}
                  />
                  <span>{expandWidget ? "Verkleinen" : "Vergroten"}</span>
                </div>
              </div>
            </div>
            {!showRemarkArea && (
              <>
                <div className="widget-period">
                  <label>{getParams(params, "APP_LABEL_PERIOD")}</label>
                  <input
                    type="date"
                    value={periodStart}
                    onChange={(e) => this.handlePeriodStartChange(e, false)}
                    onBlur={(e) => this.handlePeriodStartChange(e, true)}
                    required
                  />
                  <input
                    type="date"
                    value={periodEnd}
                    onChange={(e) => this.handlePeriodEndChange(e, false)}
                    onBlur={(e) => this.handlePeriodEndChange(e, true)}
                    required
                  />
                </div>
                <div className="widget-filters">
                  {Object.keys(filterFields).length > 0 && (
                    <table>
                      <tbody>
                        {Object.keys(filterFields).map((keyName, i) => (
                          <tr key={i}>
                            <td>
                              <span>
                                {getParams(
                                  params,
                                  `APP_LABEL_${keyName.toUpperCase()}`
                                )}
                                :
                              </span>
                            </td>
                            <td>
                              <Select
                                options={filterFields[keyName]}
                                isMulti
                                className="multi-select"
                                onChange={(event) =>
                                  this.handleChange(event, keyName)
                                }
                                value={this.state.filterValues[keyName]}
                              />
                            </td>
                            <td>
                              <FontAwesomeIcon
                                icon={faTrash}
                                onClick={() => this.closeFilter(keyName)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </>
            )}
            <div className="widget-table">
              <div className="widget-table-body">
                {!showRemarkArea ? (
                  <table>
                    <thead>
                      <tr>
                        {(expandWidget ? expTableHeader : tableHeader).map(
                          (e, i) => (
                            <th key={i}>
                              <div
                                className={
                                  "flexbox" + (e == "action" ? " center" : "")
                                }
                              >
                                {e !== "action" ? (
                                  <>
                                    <div onClick={() => this.sortData(e)}>
                                      {sortField !== e && (
                                        <FontAwesomeIcon icon={faSort} />
                                      )}
                                      {sortField === e && sortType === 0 && (
                                        <FontAwesomeIcon icon={faSort} />
                                      )}
                                      {sortField === e && sortType === 1 && (
                                        <FontAwesomeIcon icon={faSortUp} />
                                      )}
                                      {sortField === e && sortType === 2 && (
                                        <FontAwesomeIcon icon={faSortDown} />
                                      )}{" "}
                                      <span>
                                        {getParams(
                                          params,
                                          `APP_LABEL_${e.toUpperCase()}`
                                        )}
                                      </span>
                                    </div>
                                    <FontAwesomeIcon
                                      icon={faFilter}
                                      onClick={() => this.openFilter(e)}
                                    />
                                  </>
                                ) : (
                                  <input
                                    type="checkbox"
                                    onClick={(f) =>
                                      this.bulkSelect(!bulkSelectChecked)
                                    }
                                    checked={bulkSelectChecked}
                                  />
                                )}
                              </div>
                            </th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.map((e, i) => {
                          if (this.checkAllFilters(e)) {
                            return (
                              <tr key={i} className={`reqrow_${i}`}>
                                <td>{e.rsc}</td>
                                <td>{e.area}</td>
                                <td>
                                  <div className="req-flex">
                                    {e.type}
                                    {!!e.comment && !expandWidget && (
                                      <FontAwesomeIcon
                                        icon={faPaperclip}
                                        onClick={() => this.showComment(i)}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td>
                                  {getParams(params, e.status.toUpperCase())}
                                </td>
                                <td>{convertYMDToDMY(e.date)}</td>
                                {expandWidget && (
                                  <>
                                    <td>{e.shiftFrom}</td>
                                    <td>{e.shiftTo}</td>
                                    <td>{e.shiftTime}</td>
                                    <td>
                                      {!!e.dateCreated
                                        ? convertYMDToDMY(e.dateCreated)
                                        : ""}
                                    </td>
                                    <td>{e.comment}</td>
                                  </>
                                )}
                                <td className="center">
                                  <input
                                    type="checkbox"
                                    onChange={() =>
                                      this.handleCheckBoxChange(
                                        `${e.areaId},${e.rscId},${e.date},${e.newActId},${e.shiftId},${e.status}`
                                      )
                                    }
                                    checked={
                                      this.state.selectedItems.indexOf(
                                        `${e.areaId},${e.rscId},${e.date},${e.newActId},${e.shiftId},${e.status}`
                                      ) > -1
                                    }
                                    className={
                                      e.status === "APP_LABEL_REQUESTED"
                                        ? ""
                                        : "hidden"
                                    }
                                  />
                                </td>
                                {!expandWidget && (
                                  <div className="req-comment">
                                    <div>
                                      <span>Opmerking: {e.comment}</span>
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        onClick={() => this.hideComment(i)}
                                      />
                                    </div>
                                  </div>
                                )}
                              </tr>
                            );
                          }
                          return null;
                        })}
                    </tbody>
                  </table>
                ) : (
                  <div className="remarkOverlay">
                    <span>
                      {this.state.bulkUpdateType === 0
                        ? "Aanvragen goedkeuren: opmerking (optioneel)"
                        : "Aanvragen afkeuren: opmerking (optioneel)"}{" "}
                    </span>
                    <textarea
                      value={this.state.requestRemark}
                      onChange={this.handleTextareaChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="widget-buttons">
            {!showRemarkArea ? (
              <>
                {this.props.roleId !== "U" && showApproveButton && (
                  <button
                    type="button"
                    onClick={() => this.bulkUpdate(0)}
                    disabled={this.state.selectedItems.length === 0}
                  >
                    {getParams(params, "APP_BUTTON_APPROVE")}
                  </button>
                )}
                {showRejectButton && (
                  <button
                    type="button"
                    onClick={() => this.bulkUpdate(1)}
                    disabled={this.state.selectedItems.length === 0}
                  >
                    {getParams(params, "APP_BUTTON_REJECT")}
                  </button>
                )}
              </>
            ) : (
              <>
                <button type="button" onClick={() => this.sendUpdate()}>
                  {getParams(params, "APP_BUTTON_CONFIRM")}
                </button>
                <button type="button" onClick={() => this.cancelUpdate()}>
                  {getParams(params, "APP_BUTTON_CANCEL")}
                </button>
              </>
            )}
          </div>
        </div>
        {expandWidget && (
          <div className="widget-dimmer" onClick={this.toggleExpandWidget} />
        )}
      </div>
    );
  }
}

export default Widget;
