import React from "react";
import { validateRequired } from "utils/validate";
import getParams from "utils/parameters";
import Network from "utils/Network";
import "./resetpassword.scss";

class ResetPassword extends React.Component {
  state = {
    password: "",
    repeatPass: "",
    intensity: 1,
    isLoading: false,
  };

  handlePasswordChange = (event) => {
    const { value } = event.target;
    this.setState({ password: value });
  };

  handleRepeatPasswordChange = (event) => {
    const { value } = event.target;
    this.setState({ repeatPass: value });
  };

  validate = () => {
    const { password, repeatPass } = this.state;
    if (!validateRequired(password)) return false;
    if (!validateRequired(repeatPass)) return false;
    if (password !== repeatPass) return false;
    return true;
  };

  doShake = (intensity) => {
    let i = intensity;
    if (intensity !== 5) {
      i++;
    } else {
      i = 1;
    }
    this.setState({ intensity: i });

    document
      .querySelector(".resetpass-modal")
      .classList.add(`error${intensity}`);
    if (intensity === 1) {
      document.querySelector(".resetpass-modal").classList.remove(`error${5}`);
    } else {
      document
        .querySelector(".resetpass-modal")
        .classList.remove(`error${intensity - 1}`);
    }
  };

  hash = (string) => {
    return string.split("").reduce(function(prevHash, currVal) {
      return (prevHash << 5) - prevHash + currVal.charCodeAt(0);
    }, 0);
  };

  submit = () => {
    const isValid = this.validate();
    const { password, intensity } = this.state;
    if (isValid) {
      this.setState({ isLoading: true });
      Network.post(
        `${this.props.baseUrl}login`,
        `username=${this.props.resetData.username}&pwd=${this.hash(
          this.props.resetData.password
        )}&PwdRst=${this.hash(password)}`
      ).then((res) => {
        this.setState({ isLoading: false });
        if (res.status == 200) {
          this.props.undoResetPass();
        } else {
          this.doShake(intensity);
        }
      });
    } else {
      this.doShake(intensity);
    }
  };

  render() {
    const { isLoading } = this.state;
    const { params } = this.props;

    return (
      <div className="resetpass">
        <div className="resetpass-body">
          <div className="modal resetpass-modal">
            <div className="modal-header">
              <h1>{getParams(params, "APP_USR_MGM_RESET")}</h1>
            </div>
            <div className="form-field">
              <label htmlFor="password1">
                {getParams(params, "APP_LABEL_NEW_PWD")}
              </label>
              <input
                id="password1"
                type="password"
                onChange={this.handlePasswordChange}
              />
            </div>
            <div className="form-field">
              <label htmlFor="password2">
                {getParams(params, "APP_LABEL_REPEAT_NEW_PWD")}
              </label>
              <input
                id="password2"
                type="password"
                onChange={this.handleRepeatPasswordChange}
              />
            </div>
            <button
              type="submit"
              onClick={() => this.submit()}
              className={isLoading ? "loading" : ""}
            >
              {getParams(params, "APP_LABEL_CONFIRM")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
