import React from "react";
import getParams from "utils/parameters";
import Logo from "assets/img/Dehora-200px.png";
import RequestWidget from "widgets/RequestWidget";
import ScheduleWidget from "widgets/ScheduleWidget";
import MessageWidget from "widgets/MessageWidget";
import Tile from "./tile/Tile";
import Network from "utils/Network";
import Spinner from "assets/img/Dehora_Circle.png";
import "./main.scss";

class Main extends React.Component {
  state = {
    tiles: [],
    widgets: [],
    filters: [],
    loading: true,
  };

  componentDidMount() {
    const { userData, baseUrl } = this.props;
    this.getFilters(baseUrl, userData.id);

    // set tiles
    const tiles = [];
    let i = 1;
    while (i > 0) {
      let link;
      if (i < 10) {
        link = `LINK_0${i}`;
      } else {
        link = `LINK_${i}`;
      }
      if (link === getParams(this.props.params, link)) {
        i = -1;
      } else {
        tiles.push(link);
        i++;
      }
    }
    this.setState({ tiles });

    // set widgets
    const widgets = [];
    let j = 1;
    while (j > 0) {
      let widget;
      if (j < 10) {
        widget = `WIDGET_0${j}`;
      } else {
        widget = `WIDGET_${j}`;
      }
      if (widget === getParams(this.props.params, widget)) {
        j = -1;
      } else {
        widgets.push(widget);
        j++;
      }
    }
    this.setState({ widgets });
  }

  getFilters = (baseUrl, userId) => {
    Network.get(`${baseUrl}rest/Filters/` + userId)
      .then((res) => {
        if (res.error) this.props.doLogout();
        this.setState({ filters: res.filters, loading: false });
      })
      .catch(() => this.props.doLogout());
  };

  render() {
    const { tiles, widgets, filters, loading } = this.state;
    const { params, userData } = this.props;

    if (loading)
      return (
        <div className="fullscreen">
          <img src={Spinner} alt="loading" className="spinner" />
        </div>
      );

    return (
      <div className="portal">
        <div className="container">
          <div className="header">
            <div>
              <h1>{getParams(params, "APP_LABEL_PORTAL_TITLE")}</h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: getParams(params, "APP_LABEL_PORTAL_DESCRIPTION"),
                }}
              />
            </div>
            <div>
              <a
                href="https://dehora.be/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Logo} alt="dehora-logo" />
              </a>
            </div>
          </div>
          <div className="content">
            <div className="main">
              {tiles.map((e, i) => {
                const tile = userData.tiles.find(
                  (t) => t.id === getParams(params, `${e}_KEY`)
                );
                if (
                  userData.roleId === "S" ||
                  (tile &&
                    tile.display === "Y" &&
                    tile.roles.indexOf(userData.roleId) > -1)
                ) {
                  return (
                    <Tile
                      key={i}
                      url={getParams(params, e)}
                      titleClass={getParams(params, `${e}_TITLE_CLASS`)}
                      image={getParams(params, `${e}_IMG`)}
                      title={getParams(params, `${e}_TITLE`)}
                      subtitle={getParams(params, `${e}_SUB_TITLE`)}
                      text={getParams(params, `${e}_DESCRIPTION`)}
                    />
                  );
                }
                return null;
              })}
            </div>
            <div className="aside">
              {widgets.map((e) => {
                const widget = userData.widgets.find(
                  (t) => t.id === getParams(params, e)
                );
                if (
                  userData.roleId === "S" ||
                  (widget &&
                    widget.display === "Y" &&
                    widget.roles.indexOf(userData.roleId) > -1)
                ) {
                  if (getParams(params, `${e}_TYPE`) === "REQUEST") {
                    return (
                      <RequestWidget
                        title={getParams(params, `${e}_TITLE`)}
                        subtitle={getParams(params, `${e}_SUBTITLE`)}
                        tableHeader={getParams(
                          params,
                          getParams(params, `${e}_TABLEHEADERS`)
                        ).split(";")}
                        expTableHeader={getParams(
                          params,
                          getParams(params, `${e}_EXPTABLEHEADERS`)
                        ).split(";")}
                        url={getParams(params, `${e}_URL`)}
                        params={params}
                        userFilters={filters}
                        baseUrl={this.props.baseUrl}
                        rscId={userData.rscId}
                        roleId={userData.roleId}
                        doLogout={this.props.doLogout}
                      />
                    );
                  }

                  if (getParams(params, `${e}_TYPE`) === "SCHEDULE") {
                    return (
                      <ScheduleWidget
                        title={getParams(params, `${e}_TITLE`)}
                        subtitle={getParams(params, `${e}_SUBTITLE`)}
                        tableHeader={getParams(
                          params,
                          getParams(params, `${e}_TABLEHEADERS`)
                        ).split(";")}
                        expTableHeader={getParams(
                          params,
                          getParams(params, `${e}_EXPTABLEHEADERS`)
                        ).split(";")}
                        url={getParams(params, `${e}_URL`)}
                        params={params}
                        baseUrl={this.props.baseUrl}
                        doLogout={this.props.doLogout}
                      />
                    );
                  }

                  if (getParams(params, `${e}_TYPE`) === "MESSAGE") {
                    return (
                      <MessageWidget
                        title={getParams(params, `${e}_TITLE`)}
                        subtitle={getParams(params, `${e}_SUBTITLE`)}
                        tableHeader={getParams(
                          params,
                          getParams(params, `${e}_TABLEHEADERS`)
                        ).split(";")}
                        expTableHeader={getParams(
                          params,
                          getParams(params, `${e}_EXPTABLEHEADERS`)
                        ).split(";")}
                        url={getParams(params, `${e}_URL`)}
                        params={params}
                        baseUrl={this.props.baseUrl}
                        doLogout={this.props.doLogout}
                      />
                    );
                  }
                }
                return null;
              })}
            </div>
          </div>
          <div className="footer">
            <div className="copyright">
              <span title={getParams(params, "APPLICATION_DB")}>
                Copyright 2020 Déhora - Alle rechten voorbehouden.
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
