import React from 'react';
import getParams from 'utils/parameters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faPowerOff, faKey } from '@fortawesome/free-solid-svg-icons';
import './banner.scss';

class Banner extends React.Component {
  state = {
    languages: [],
    selectedLang: null,
  };

  componentDidMount() {
    this.setState({ languages: getParams(this.props.parameters, 'APP_LANGUAGES').split(';') });
    this.setState({ selectedLang: getParams(this.props.parameters, 'APP_LANGUAGE') });
  }

  setLanguage = lang => {
    this.setState({ selectedLang: lang });
    this.props.getParameters(this.props.baseUrl, lang);
  };

  render() {
    const { isLoggedIn, parameters, userData, openPasswordModal } = this.props;
    const { languages, selectedLang } = this.state;

    return (
      <div className="banner">
        <div className="title">{getParams(parameters, 'APP_LABEL_PORTAL_TITLE')}</div>
        <div>
          <div className="lang">
            <span>{getParams(parameters, 'APP_LABEL_LANGUAGE')}</span>{' '}
            <div className="lang-select">
              <span>{selectedLang}</span>
              <div className="lang-dropdown">
                {languages.map(e => {
                  if (e !== selectedLang) {
                    return (
                      <span key={e} onClick={() => this.setLanguage(e)}>
                        {e}
                      </span>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </div>
          {isLoggedIn && (
            <div className="user">
              <div>
                <FontAwesomeIcon icon={faUserCircle} />
                <span>{userData.firstName}</span>
              </div>
              <div className="user-info">
                <span>
                  {userData.name} ({userData.username})
                </span>
                <span>{userData.roleName}</span>
                <span />
                <a href="#" className="logout" onClick={openPasswordModal}>
                  <FontAwesomeIcon icon={faKey} />
                  {getParams(parameters, 'APP_LOGIN_CHANGE_PWD')}
                </a>
                <a href="login?logout" className="logout">
                  <FontAwesomeIcon icon={faPowerOff} />
                  {getParams(parameters, 'APP_LABEL_MENU_LOG_OUT')}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Banner;
